import React from 'react'
import './Portfolio.css';
import Carte_projet from '../comp/Carte_projet';
import projets from '../data/projets_data';

export default function Portfolio({ language }) {

    return (
        <div className='PF_gallerie'>
            {projets.map((projet, i) => {
                return (
                    <Carte_projet
                        key={i}
                        projet={projet}
                        language={language}
                    />
                )
            })}
        </div>
    )
}
