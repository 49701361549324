import React from 'react'
import './Boutique.css'

export default function Boutique() {
  return (
    <div className='BO_main'>
      <div className='BO_titre'>Boutique</div>
    </div>

  )
}
