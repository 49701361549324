import React from 'react'
import './Apropos.css'

export default function Apropos({ language }) {

  return (
    <div className='A_main'>

      {language === "EN" ?
        <div className='A_sqr A_sqr1'>
          <h2 className='A_titre'>About me</h2>
          <p>I am an illustrator and knowledge mediator through art. I use watercolors, colored pencils and digital drawings to talk about many different subjects. Through colorful compositions, I question the place and relationship that humans have with their environment and the living. </p>
          <p> In 2021, I created collectif rhizoma with Anaëlle Alvarez-Caraire, to lead pedagogical and popular science projects, through visual, kinesthetic and sound creations. We particularly collaborated with the Third-Place Simone (arts training camp) and the Conahcyt (Consejo nacional de humanidades, ciencias y tecnologías - National Council of Humanities, Sciences, and Technologies in Mexico) on projects that combined art and social connections.</p>
          <p> I have two Master’s degrees, in Art History - Specialty pre-Hispanic art (Paris-Sorbonne University, 2017) and International Cultural Projects Management (University of Versailles Saint-Quentin-en-Yvelines, 2018). I also have a National Postgraduate Degree in Art and Visual Didactic (Haute Ecole des Arts du Rhin - HEAR Strasbourg, 2022). </p>,
        </div>

        :
        <div className='A_sqr A_sqr1'>
          <h2 className='A_titre'>À propos</h2>
          <p>Je suis illustratrice et médiatrice des savoirs par l’art. J’utilise l’aquarelle, les crayons de couleur et le dessin numérique pour traiter de nombreux sujets. A travers des compositions colorées, mon travail interroge la place et le rapport de l’humain avec son environnement et avec le vivant.</p>
          <p>En 2021, j’ai créé le collectif rhizoma avec Anaëlle Alvarez-Caraire pour mener des projets de transmission et de vulgarisation scientifique, à travers des créations visuelles, plastiques et sonores. Nous avons notamment collaboré avec le Tiers-Lieu Simone (camp d’entraînement artistique) et le Conahcyt (Consejo nacional de humanidades, ciencias y tecnologías - Conseil national des humanités, sciences et technologies du Mexique) sur des projets mêlant art et lien social. </p>
          <p>Je suis diplômée d’une Maîtrise en Histoire de l’art - spécialité art préhispanique de l’université Paris-Sorbonne (2017), d’un Master Projets culturels internationaux de l’université de Versailles Saint-Quentin-en-Yvelines (2018) et d’un Diplôme National Supérieur d’Expression Plastique (DNSEP) de Didactique visuelle de la Haute École des arts du Rhin (2022). </p>
        </div>
      }

      <div className='A_sqr A_sqr2'>
        <img
          src={require('../data/img/apropos2.png')}
          alt="artiste dessinant avec des crayons de couleurs"
          className='A_sqr_img'
        />
      </div>

      <div className='A_sqr A_sqr3'>
        <img
          src={require('../data/img/apropos3.png')}
          alt="artiste et collaborateurs penchés sur un ouvrage au centre d'une table"
          className='A_sqr_img'
        />
      </div>

      {
        language === "EN" ?
          <div className='A_sqr A_sqr4'>
            <h2 className='A_titre'>Let's work together</h2>
            <p>I collaborate with different types of clients: private, companies, institutions… I offer my services in illustration and graphic design for all kinds of projects. Communication and cooperation are always at the heart of my creative process.
              Thanks to my experience and expertise in visual didactic, I help my clients sum up visually complex information, design adapted content and create pedagogical tools.</p>
          </div>
          :
          <div className='A_sqr A_sqr4'>
            <h2 className='A_titre'>Travailler ensemble</h2>
            <p>Je collabore avec différents types de clients: particuliers, entreprises, institutions, etc. Je propose mes services d’illustration et de graphisme pour toutes sortes de projets. La communication et la coopération sont toujours au centre de mon processus créatif.
              Grâce à mon expérience et à mon expertise en didactique visuelle et pédagogie par l’art, j ’accompagne mes clients dans la synthétisation visuelle d’informations complexes, la mise en forme de contenus adaptés et la création de dispositifs de vulgarisation.</p>
          </div>
      }


    </div>
  )
}
