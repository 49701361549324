import React from 'react';
import './Contact.css';
import { useState } from 'react';
import emailjs from '@emailjs/browser';

export default function Contact({ language }) {

  const [datas, setDatas] = useState({
    nom: '',
    prenom: '',
    email: '',
    message: ''
  })

  function handleChange(e) {
    setDatas({ ...datas, [e.name]: e.value })
    // console.log(datas)
  }

  function handleSubmit(e) {

    e.preventDefault()

    //check params;
    if (datas.email === "") {
      setError("email non valide !");
      return;
    } else if (datas.nom === "" && datas.prenom === "" && datas.message === "") {
      setError("informations manquantes !");
      return;
    } else {
      setError(false);
    }

    let templateParams = {
      message: datas.message,
      nom: datas.nom,
      prenom: datas.prenom,
      email: datas.email,
    }

    sendMessage(templateParams)
  }

  const [stateMessage, setStateMessage] = useState("none")
  const [error, setError] = useState(false);

  function sendMessage(templateParams) {

    setStateMessage("loading")

    emailjs
      .send('service_u9yptul', 'template_4outvvb', templateParams, {
        publicKey: 'wMddJ1RsZbklmlc2J',
      })
      // .send('service_68anbwi', 'template_vernyge', templateParams, {
      //   publicKey: 'lgskQmtPoj3AU-TXQ',
      .then(
        () => {
          setStateMessage("success")
          console.log('SUCCESS!');
        },
        (error) => {
          setStateMessage("error")
          console.log('FAILED...', error.text);
        },
      );
  }

  function handleCloseModale() {
    setStateMessage("none")
    setDatas({
      nom: '',
      prenom: '',
      email: '',
      message: ''
    })
  }

  return (
    <div className='Co_ext'>

      {(stateMessage === "loading" || stateMessage === "error" || stateMessage === "success") &&
        <div className='Co_modale_wrap'>
          <div className='Co_modale_box'>
            <div
              className='Co_modale_close'
              onClick={() => handleCloseModale()}
            >X</div>
            {stateMessage === "loading" && <p className='Co_modale_message'>Message en cours d'envoi !</p>}
            {stateMessage === "success" && <p className='Co_modale_message'>Merci pour votre message !</p>}
            {stateMessage === "error" && <p className='Co_modale_message'>Une erreur s'est produite. Merci de réessayer !</p>}
          </div>
        </div>}

      <div className='Co_main'>

        {/* <div className='Co_form_part_wrap_all'> */}

        <div className='Co_LEFT_COL'>

          <div className='Co_txt_block'>
            {language === "EN" ?
              <>
                <h2 className='Co_h2'>Contact</h2>
                <p className='Co_txt'>I work in Toulouse and travel around France according to the projects I collaborate on.
                </p>
                <p className='Co_txt'>Want to collaborate?  Ask me a question? Make a comment? Contact me through the form below or via DM on <a href='https://www.instagram.com/suzonleger/' target="blank"
                  className='Co_txt_link'
                >My Instagram page</a>.</p>
              </>
              :
              <>
                <h2 className='Co_h2'>Contact</h2>
                <p className='Co_txt'>Je travaille à Toulouse et me déplace dans toute la France selon le besoin des projets.</p>
                <p className='Co_txt'>{'Une envie de collaboration\xa0? Une question\xa0? Une remarque\xa0? '}</p>
                <p className='Co_txt'>Contactez-moi via le formulaire ci-dessous ou via
                  <a
                    href='https://www.instagram.com/suzonleger/'
                    target="blank"
                    className='Co_txt_link'
                  > ma page Instagram</a>
                  .</p>
              </>
            }
          </div>

          <form className='Co_form_main'>

            <div className='Co_form_display'>

              <div className='Co_form_label_box'>
                <label htmlFor="nom">{language === "EN" ? "NAME" : "NOM"}</label>
                <input
                  className='Co_form_input1'
                  id="nom"
                  name="nom"
                  type="text"
                  value={datas.nom}
                  onChange={(e) => handleChange(e.target)}
                />
              </div>

              <div className='Co_form_label_box'>
                <label htmlFor="prenom">{language === "EN" ? "FIRSTNAME" : "PRENOM"}</label>
                <input
                  className='Co_form_input1'
                  id="prenom"
                  name="prenom"
                  type="text"
                  value={datas.prenom}
                  onChange={(e) => handleChange(e.target)}>
                </input>
              </div>
            </div>

            <div className='Co_form_label_box'>
              <label htmlFor="mail">{language === "EN" ? "EMAIL" : "EMAIL"}</label>
              <input
                className='Co_form_input2'
                id="email"
                name="email"
                type="email"
                value={datas.email}
                required
                onChange={(e) => handleChange(e.target)}>
              </input>
            </div>

            <div className='Co_form_label_box'>
              <label htmlFor="message">{language === "EN" ? "MESSAGE" : "MESSAGE"}</label>
              <textarea
                className='Co_form_textarea'
                id="message"
                name="message"
                type="text"
                value={datas.message}
                onChange={(e) => handleChange(e.target)}>
              </textarea>

            </div>

            <button
              className='Co_form_btn'
              onClick={(e) => handleSubmit(e)}>
              {language === "EN" ? "send" : "envoyer"}</button>


            {error &&
              <p className='Co_form_erreur'>{"Erreur dans le formulaire : " + error}</p>
            }

          </form >

        </div>

        <div className='Co_pic_part'>
          <img
            src={require('../data/img/contact.png')}
            alt="bureau de l'artiste avec feuilles et peinture"
            className='Co_pic'
          />
        </div>

        {/* </div> */}



      </div >


    </div>

  )
}
