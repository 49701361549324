const langues = ["FR", "EN"]

const sous_titre = {
    name_FR: "illustration, vulgarisation scientifique et muséale",
    name_EN: "illustrations, knowledge mediation throught art"
}

const parties = [
    {
        name_FR: "portfolio",
        name_EN: "portfolio",
        url: "/"
    },
    {
        name_FR: "ateliers",
        name_EN: "workshop",
        url: "ateliers"
    },
    {
        name_FR: "boutique",
        name_EN: "shop",
        url: "https://www.etsy.com/fr/shop/SuzonLeger?ref=profile_header" 
    },
    {
        name_FR: "à propos",
        name_EN: "about me",
        url: "apropos"
    },
    {
        name_FR: "contact",
        name_EN: "contact",
        url: "contact"
    }
]


const data_titres = { parties, langues, sous_titre }
export default data_titres