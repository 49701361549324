import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { useState } from "react";

import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Nav from './routes/Nav';
import ErrorPage from './error_page';
import Contact from './routes/Contact';
import Apropos from './routes/Apropos';
import Portfolio from './routes/Portfolio';
import AtelierStage from './routes/AtelierStage';
import Projet from './routes/Projet';
import Boutique from './routes/Boutique';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {

  const [language, setLanguage] = useState("FR")

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Nav language={language} setLanguage={setLanguage} />,
        errorElement: <ErrorPage />,

        children: [
          // {
          //   index: true,
          //   element: <Navigate to="/portfolio" replace />
          // },
          {
            path: "/",
            element: <Portfolio language={language} />,
          },
          {
            path: "portfolio/:projetId",
            element: <Projet language={language} />,
          },
          {
            path: "ateliers",
            element: <AtelierStage language={language} />,
          },
          {
            path: "boutique",
            element: <Boutique language={language} />
          },
          {
            path: "apropos",
            element: <Apropos language={language} />,
          },
          {
            path: "contact",
            element: <Contact language={language} />,
          },
        ],
      },
    ],
    {

    }
  )
  return (
    <RouterProvider router={router} />
  )
}

root.render(
  <>
    <div>
      <App />
    </div>

    <div className='index_tel_bottom_marge'>
      <p className='index_name'>@Suzon Léger 2024</p>
    </div>
  </>

);

// root.render(
//   <RouterProvider router={router} />
// );