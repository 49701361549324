//Liste des projets affichés sur la page PORTOLIO



//Chaque propriété peut être en Français et en Anglais, pour cela il faut ajouter _FR ou _EN à la fin de la propriété

//Si une prop n'existe qu'en FR et pas EN, si la lange sélectionnée est l'anglais, par défaut on aura la version FR
//ça peut être utilie pour les dates, pas besoin de mutliplier les lignes

//Si une prop manque, un message sera affiché dans la console : <<<!>année manquante <!>>> 
//et sur la page projet liée

//Pour mettre en ITALIQUE
// 1 - Ajouter une balise span avec la classe P_txt_italic avec le texte à mettre en italique dedans
// 2 - Ne pas mettre de guillemts pour le texte
// voir exemple projet 81, description_FR

//Si besoin de faire d'autres zones de texte différents, même process et ajouter la nouvelle classe dans le fichier Projet.css
//Par exemple une zone en rouge : <span className="P_txt_red">Ma zone en rouge</span>
//Et dans le fichier css : 
//  .P_txt_red{
//      color: red;
//  }

// eslint-disable-next-line

let projets_suzon = [
    {
        id: 81,

        titre_FR: "Abrazamos la tierra",
        titre_EN: "We embrace the earth",

        technique_FR: "peintures, broderie et poésie sur tissu",
        technique_EN: "paintings, embroidery and poetry on fabric",

        taille_FR: "dimensions variables, 7 pièces de 22x30 cm à 30x120 cm",
        taille_EN: "different sizes, 7 pieces of 22x30 cm to 30x120 cm",

        année_FR: "2024",
        année_EN: "2024",

        description_FR: <p>Cette série d’illustrations fait partie du projet « Del corazón a las manos ». Exposée aux côtés d’une cabane brodée monumentale, elle vise à accompagner et inspirer les créations de la broderie participative. Chaque verso présente le vers d’un petit poème imaginé pour l’exposition.

            <span className="P_txt_italic"> Cette série est presenté à Kalan - Centro Nacional de Culturas de Salud y Bienestar, Ville de Mexico, Mexique. </span></p>,

        description_EN: <p>This series of illustrations was part of the project “Del corazón a las manos” (From the heart to the hands). It was exhibited alongside a gigantic embroidered shed, to accompany and inspire creations of participatory embroidery. Each illustration has at its back a verse from a small poem that was imagined for the exhibition.
            <span className="P_txt_italic"> Those illustrations are exhibited in Kalan - Centro Nacional de Culturas de Salud y Bienestar, Mexico City, Mexico. </span></p>,

        img_couverture: "mexique-projet6.png", alt_couverture: "mains ocres enlaçant une montagne",
        img_grille: "grille-81.png", alt_grille: "plusieurs peintures colorées et broderie sur des tissus représentant des montagnes, des femmes qui tissent, qui s'emlaçent, des mains au travail, etc",
        img_grille_tel: "grille-tel-81.png", alt_grille_tel: "plusieurs peintures colorées et broderie sur des tissus représentant des montagnes, des femmes qui tissent, qui s'emlaçent, des mains au travail, etc",

    },
    {
        id: 82,
        titre_FR: "Le chant des montagnes",
        titre_EN: "The song of mountains",

        technique_FR: "aquarelles et crayons de couleurs",
        technique_EN: "watercolor and colored pencil",

        taille_FR: "dimensions variables, de 10x15 cm à 50x60 cm",
        taille_EN: "different sizes, about 10x15 cm to 50x60 cm",
        année: "2022-2024",
        description_FR: <p>Les montagnes exercent le même pouvoir d'attraction et de séduction que le chant des sirènes. Dans cette exposition individuelle (août 2024, Carla Bayle), le trait et la couleur tentent de rendre hommage aux paysages de montagne et aux esprits qui les peuplent.
            <span className="P_txt_italic">Cette exposition est le fruit d'un travail de recherches et de créations personnelles. </span></p>,
        description_EN: <p>The mountains have the same power of attraction and seduction as the song of mermaids. In this solo exhibition (August 2024, Carla Bayle), lines and colors intend to pay tribute to the mountainous landscapes and to the spirits that live within.
            <span className="P_txt_italic">This exhibition is the result of long research work and artistic exploration.</span></p>,


        img_couverture: "montagne-projet9.png", alt_couverture: "aquarelle dans les tons bleus et rose avec une femme-montagne endormie",
        img_grille: "grille-82.png", alt_grille: "plusieurs aquarelles de montagnes dans des couleurs vives",
        img_grille_tel: "grille-tel-82.png", alt_grille_tel: "plusieurs aquarelles de montagnes dans des couleurs vives",

    },
    {
        id: 83,
        titre_FR: "Maisons",
        titre_EN: "Home",

        technique_FR: "crayon gris",
        technique_EN: "pencil",

        taille_FR: "44 pages, 10x15 cm",

        année: "2023",

        description_FR: "Maisons est un projet auto-édité, écrit à la première personne du singulier. La narratrice interroge la définition de la maison et le sentiment d’avoir un « chez soi », alors qu’elle-même cherche à re-définir son appartenance à une terre et/ou à un groupe.",
        description_EN: "Home is a self-edited project written in the first person. The narraot questions the definition of a house and of the feeling of having a home, while trying to define her own belonging to a territory and/or to a group.",

        img_couverture: "maisons-projet4.png",
        alt_couverture: "livre ouvert avec du texte à gauche et une image en gris à droite",
        img_grille: "grille-83.png",
        alt_grille: "ensemble de pages ouvertes avec des dessins en noir et blanc",
        img_grille_tel: "grille-tel-83.png",
        alt_grille_tel: "ensemble de pages ouvertes avec des dessins en noir et blanc",

    },
    {
        id: 84,
        titre_FR: "Le genre en recherche",
        titre_EN: "Gender in research",

        technique_FR: "illustrations numérique",
        technique_EN: "digital illustrations",

        taille_FR: "dimensions variables",
        taille_EN: "different sizes",

        année: "2023",
        description_FR: <p>Ces illustrations ont été conçues pour accompagner l’article « De la nécessité de considérer le genre en recherche biomédicale » de la chercheuse en physiologie animale, Colette Denis. L’article traite des biais et des stéréotypes de genre qui existent dans la recherche. Ceux-ci impactent grandement la qualité des résultats et des potentielles solutions apportées aux patient·es, selon les pathologies. L’article est <a className="P_txt_link" href='https://museum.toulouse-metropole.fr/de-la-necessite-de-considerer-le-genre-en-recherche-biomedicale/' target="blank" > disponible en ligne</a> et en version papier dans les points de distribution du Muséum. </p>,
        description_EN: <p>Those illustrations were created in order to accompany the article “About the necessity to consider gender in the biomedical research”, written by the researcher in animal physiology Colette Denis. The article discusses gender bias and stereotypes that exist in research and have an impact on the quality of the results and the potential solutions brought to the patients, according to their pathology. The article is <a className="P_txt_link" href='https://museum.toulouse-metropole.fr/de-la-necessite-de-considerer-le-genre-en-recherche-biomedicale/' target="blank" > available in digital version</a> as well as in print at the Toulouse museum’s distribution points.</p>,

        img_couverture: "illumed-projet3.png", alt_couverture: "dessin numérique avec une balance qui symbolise les inégalités homme/femme",
        img_grille: "grille-84.png", alt_grille: "ensemble de dessins présentant de manière visuelle des inégalités de genre dans la recherche",
        img_grille_tel: "grille-tel-84.png", alt_grille_tel: "ensemble de dessins présentant de manière visuelle des inégalités de genre dans la recherche",

    },
    {
        id: 85,
        titre_FR: "Ici, tout va bien",
        titre_EN: "So far, so good",

        technique_FR: "dessins aux crayons de couleur",
        technique_EN: "colored pencil drawings",

        taille_FR: "dimensions variables",
        taille_EN: "different sizes",
        année: "2023",

        description_FR: "Dans les carnets de dessin, naissent des illustrations issues de randonnées et de voyages. D’abord prises sur le vif par touches de couleurs, les images peuvent être retravaillées par la suite pour se transformer en cartes postales et illustrations, ou s’intégrer dans d’autres projets.",
        description_EN: "In sketchbooks, illustrations emerge, inspired by hikes and trips. First taken on the spot, the pictures can later be reworked to become postcards and illustrations, or part of other projects.",

        img_couverture: "montagnes-projet5.png", alt_couverture: "dessin colorée (non réaliste) de montagnes aux crayons de couleur",
        img_grille: "grille-85.png", alt_grille: "ensemble de dessins aux crayons de couleurs extrait de carnet de voyages",
        img_grille_tel: "grille-tel-85.png", alt_grille_tel: "ensemble de dessins aux crayons de couleurs extrait de carnet de voyages",

    },
    {
        id: 86,
        titre_FR: "Garonne",
        titre_EN: "Garonne",

        technique_FR: "dessin numérique",
        technique_EN: "digital drawing",

        taille_FR: "40x30 cm",
        taille_EN: "40x30 cm",

        année: "2023",

        description_FR: "Cette illustration a été réalisée dans le cadre de l’exposition collective « Elle et la ville rose » (mars 2023), organisée par la galerie Illustrafemmes à Toulouse. La Garonne apparaît sous les traits d’une femme/déesse, dont les cheveux se transforment en eau et qui veille sur un élément architectural représentatif de la ville : le dôme de la Grave, ancien hospice et maternité toulousaine.",
        description_EN: "This illustration was created on the occasion of the collective exhibition “Elle et la ville rose” (She and the pink city, March 2023), organized by the gallery Illustrafemmes in Toulouse. The river Garonne appears as a woman/goddess, with her hair becoming water. She takes care of a symbolic architectural element of the city : the dome of the Grave, former hospice and maternity hospital in Toulouse.",

        img_couverture: "garonne-projet8.png", alt_couverture: "dessin numérique avec des teintes dominantes orange et turquoise présentant un personnage feminin devant notre dame de la grave à toulouse",
        img_grille: "grille-86.png", alt_grille: "la femme enlace l'architecture de la ville, ses cheveux se transforment en fleuve",
        img_grille_tel: "grille-tel-86.png", alt_grille_tel: "la femme enlace l'architecture de la ville, ses cheveux se transforment en fleuve",

    },
    {
        id: 87,
        titre_FR: "Les déesses des montagnes",
        titre_EN: "The goddesses of the mountains",

        technique_FR: "dessins numérique",
        technique_EN: "digital drawings",

        taille_FR: "30x40 cm et 40x30 cm",
        taille_EN: "30x40 cm and 40x30 cm",

        année: "2023",

        description_FR: "Dans de nombreuses cosmogonies, l'eau et/ou la terre sont des forces féminines comme Asintmah, Gaïa, Ganga, Pachamama, Prithvi, Sequana, etc. Ces illustrations revisitent visuellement un certain nombre de légendes et de mythes liés aux montagnes. Dans ces images comme dans le réel, le paysage est vivant et habité.",
        description_EN: "In many different cosmogonies, water and/or earth are female entities such as Asinthmah, Gaïa, Ganga, Pachamama, Prithvi, Sequana... Those illustrations visually revisit some legends and mythologies related to the mountains. As in real life, the landscape in those pictures is alive and inhabited.",

        img_couverture: "deesse-projet11.png", alt_couverture: "dessin numérique présentant des femmes comme des montagnes",
        img_grille: "grille-87.png", alt_grille: "ensemble de dessins présentant des femmes fortes, comme des éléments du paysage",
        img_grille_tel: "grille-tel-87.png", alt_grille_tel: "ensemble de dessins présentant des femmes fortes, comme des éléments du paysage",

    },
    {
        id: 88,
        titre_FR: "Nei = Nous",
        titre_EN: "Nei = US",

        technique_FR: "illustrations et mise en page, dessins numérique publiés dans l'Aujone n°3",
        technique_EN: "illustrations and layout, digital drawings published in the 3rd edition of the magazine l'Aujone",

        taille_FR: "",
        taille_EN: "",
        année: "2022-2023",

        description_FR: <p>Ces illustrations ont été conçues pour accompagner l’article « Nei : la quête de ce qui nous lie » de Clémence Losserand, réalisatrice et paysanne. En fwoïn, une des langues kanak, Nei signifie « nous, avec toi ». Dans cet article, l’autrice interroge notre rapport à la terre et à ce qui nous relie à elle. Elle replace ses réflexions à l’aune de sa propre expérience de femme ayant vécue entre la Nouvelle-Calédonie et l’arrière-pays niçois. L’article est <a className="P_txt_link"  href='https://www.calameo.com/read/007126057bc2e71abcae2?page=1' target="blank" > disponible en ligne</a>.</p>,

        description_EN: <p>Those illustrations have been conceived to accompany the article “Nei: the search of what binds us” written by Clémence Losserand, filmmaker and farmer. In fwoin, one of the Kanak languages, Nei means “us, with you”. In this article, the writer questions our relationship with the land  and what binds us to it. She puts her thoughts in the light of her own experience as a woman who lived between New Caledonia and the hinterland of Nice. The article is  <a  className="P_txt_link" href='https://www.calameo.com/read/007126057bc2e71abcae2?page=1' target="blank" >available online</a>.</p>,

        img_couverture: "Aujone-projet2.png", alt_couverture: "une revue en noir et blanc est tenue ouverte par des mains",
        img_grille: "grille-88.png", alt_grille: "série de dessins en noir et blanc présentant des femmes et des mains au travail",
        img_grille_tel: "grille-tel-88.png", alt_grille_tel: "série de dessins en noir et blanc présentant des femmes et des mains au travail",

    },
    {
        id: 89,
        titre_FR: "Au temps pour moi",
        titre_EN: "At time for me",

        technique_FR: "pastels et crayons de couleur",
        technique_EN: "pastels and colored pencils",

        taille_FR: "64 pages, 19x25 cm",
        taille_EN: "",

        année: "2022",

        description_FR: <p> <span className="P_txt_italic"> Au temps pour moi </span>est une bande dessinée personnelle, intime, écrite et réalisée à partir d’anecdotes, de questionnements, de réflexions philosophiques et de témoignages autour de la notion de temps et de la perception que nous en avons. Elle a été éditée en quelques exemplaires.</p>,
        description_EN: <p> <span className="P_txt_italic"> Au temps pour moi </span> [which, translated literally, would be “At time for me” in French but actually means “My mistake”] is a personal and intimate graphic novel, using anecdotes, questions, philosophical thoughts and testimonies to illustrate the notion of time and how we perceive it. It was edited in few copies.	</p>,

        img_couverture: "BD-mockup-projet1.png", alt_couverture: "bande dessinée tenue ouverte sur une double page au pastel avec des dominantes de bleu",
        img_grille: "grille-89.png", alt_grille: "ensemble de pages ouvertes de la bande dessinée",
        img_grille_tel: "grille-tel-89.png", alt_grille_tel: "ensemble de pages ouvertes de la bande dessinée",

    }

]

let projets = projets_suzon

export default projets