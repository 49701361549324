import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import './Nav.css';
import { Fragment, useEffect, useState } from "react";
import data_titres from "../data/titres";
import ScrollToTop from "../comp/ScrollToTop";

export default function Nav(
    { language, setLanguage }
) {

    const [modaleMenuTel, setModaleMenuTel] = useState(false)

    useEffect(() => {
        console.log(modaleMenuTel)
    }, [modaleMenuTel])

    return (
        <>
            <ScrollToTop />
            {window.innerWidth > 500 &&
                <div>
                    <div className="N_top">
                        <a className="N_top_left"
                            href="https://www.instagram.com/suzonleger/"
                            target="blank"
                            aria-label="compte instagram de l'artiste"
                        >
                            <svg
                                id="logo_insta"
                                width="30px"
                                height="30px"
                                viewBox="0 0 800 800"
                                xmlSpace="preserve"
                            >
                                <g>
                                    <path d="M395.8,97c-83.1,0-93.5,0.4-126.1,1.8c-32.6,1.5-54.8,6.6-74.2,14.2c-20.1,7.8-37.2,18.3-54.2,35.3
		c-17,17-27.5,34.1-35.3,54.2c-7.6,19.5-12.7,41.7-14.2,74.2c-1.5,32.6-1.8,43.1-1.8,126.1s0.4,93.5,1.8,126.1
		c1.5,32.6,6.7,54.8,14.2,74.2c7.8,20.1,18.3,37.2,35.3,54.2c17,17,34.1,27.5,54.2,35.3c19.5,7.6,41.7,12.7,74.2,14.2
		c32.6,1.5,43,1.8,126.1,1.8c83.1,0,93.5-0.4,126.1-1.8c32.6-1.5,54.8-6.6,74.3-14.2c20.1-7.8,37.1-18.3,54.1-35.3
		c17-17,27.5-34.1,35.3-54.2c7.5-19.5,12.7-41.7,14.2-74.2c1.5-32.6,1.8-43,1.8-126.1s-0.4-93.5-1.8-126.1
		c-1.5-32.6-6.7-54.8-14.2-74.2c-7.8-20.1-18.3-37.2-35.3-54.2c-17-17-34-27.5-54.2-35.3c-19.5-7.6-41.7-12.7-74.3-14.2
		C489.2,97.4,478.8,97,395.8,97L395.8,97z M368.4,152.1c8.1,0,17.2,0,27.4,0c81.7,0,91.3,0.3,123.6,1.8c29.8,1.4,46,6.3,56.8,10.5
		c14.3,5.5,24.4,12.2,35.1,22.9c10.7,10.7,17.3,20.9,22.9,35.2c4.2,10.8,9.2,27,10.5,56.8c1.5,32.2,1.8,41.9,1.8,123.6
		s-0.3,91.3-1.8,123.6c-1.4,29.8-6.3,46-10.5,56.8c-5.5,14.3-12.2,24.4-22.9,35.1c-10.7,10.7-20.9,17.3-35.1,22.9
		c-10.8,4.2-27,9.2-56.8,10.5c-32.2,1.5-41.9,1.8-123.6,1.8c-81.7,0-91.4-0.3-123.6-1.8c-29.8-1.4-46-6.4-56.8-10.5
		c-14.3-5.5-24.5-12.2-35.2-22.9c-10.7-10.7-17.3-20.9-22.9-35.2c-4.2-10.8-9.2-27-10.5-56.8c-1.5-32.2-1.8-41.9-1.8-123.6
		s0.3-91.3,1.8-123.6c1.4-29.8,6.3-46,10.5-56.8c5.5-14.3,12.2-24.5,22.9-35.2c10.7-10.7,20.9-17.3,35.2-22.9
		c10.8-4.2,27-9.2,56.8-10.5C300.5,152.5,311.4,152.1,368.4,152.1L368.4,152.1z M559.1,202.9c-20.3,0-36.7,16.4-36.7,36.7
		c0,20.3,16.4,36.7,36.7,36.7c20.3,0,36.7-16.4,36.7-36.7S579.4,202.9,559.1,202.9L559.1,202.9z M395.8,245.8
		c-86.7,0-157.1,70.3-157.1,157.1s70.3,157,157.1,157s157-70.3,157-157S482.6,245.8,395.8,245.8L395.8,245.8z M395.8,300.9
		c56.3,0,102,45.6,102,102c0,56.3-45.6,102-102,102c-56.3,0-102-45.6-102-102C293.9,346.5,339.5,300.9,395.8,300.9z"/>
                                </g>
                            </svg>
                        </a>
                        <div className="N_top_middle">
                            <h1 className="N_titre">Suzon Léger </h1>
                            <p className="N_soustitre">{data_titres.sous_titre["name_" + language] !== undefined ? data_titres.sous_titre["name_" + language] : data_titres.sous_titre.name_FR}</p>
                        </div>
                        <div className="N_top_right">
                            <button
                                onClick={() => setLanguage("FR")}
                                className="N_btn_langue"
                            ><p>FR</p></button>
                            {
                                data_titres.langues.length > 1 &&
                                data_titres.langues.map((el, i) => {
                                    if (i > 0)
                                        return (
                                            <Fragment key={i}>
                                                <p className="N_btn_separator">|</p>
                                                <button
                                                    onClick={() => setLanguage(el)}
                                                    className="N_btn_langue"
                                                ><p>{el}</p></button>
                                            </Fragment>
                                        )
                                })}
                        </div>

                    </div>

                    <nav>
                        <ul className="N_lst">
                            {data_titres.parties.map((el, i) => {

                                if (el.name_FR === "boutique") {
                                    return (
                                        <li className="N_item" key={i}>
                                            <a
                                                target="blank"
                                                href={el.url}
                                                // className={({ isActive, isPending }) =>
                                                //     isPending ? "pending" : isActive ? "active" : ""
                                                // }
                                            >{el["name_" + language] !== undefined ? el["name_" + language].toUpperCase() : el.name_FR.toUpperCase()}
                                            </a>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className="N_item" key={i}>
                                            <NavLink
                                                to={el.url}
                                                className={({ isActive, isPending }) =>
                                                    isPending ? "pending" : isActive ? "active" : ""
                                                }
                                            >{el["name_" + language] !== undefined ? el["name_" + language].toUpperCase() : el.name_FR.toUpperCase()}
                                            </NavLink>
                                        </li>
                                    )
                                }


                            })}
                        </ul>
                    </nav>
                </div >
            }

            {
                window.innerWidth <= 500 &&
                <div className="N_tel_top">
                    <h1 className="N_tel_titre">Suzon Léger</h1>

                    <div>

                        {!modaleMenuTel &&
                            <>
                                <button
                                    onClick={() => setModaleMenuTel(true)}
                                    className="N_tel_btn"
                                    aria-label={language === "FR" ? "ouvrir le menu" : "open menu"}
                                >
                                    <div className="N_tel_btn_barre1"></div>
                                    <div className="N_tel_btn_barre1"></div>
                                    <div className="N_tel_btn_barre1"></div>
                                </button>
                            </>
                        }

                        {modaleMenuTel &&
                            <div className="N_tel_menu_wrap">
                                <button
                                    onClick={() => setModaleMenuTel(false)}
                                    className="N_tel_btn"
                                    aria-label={language === "FR" ? "fermer le menu" : "close menu"}
                                >
                                    <div className="N_tel_btn_barre2"></div>
                                    <div className="N_tel_btn_barre3"></div>
                                </button>

                                <nav>
                                    <ul className="N_tel_lst">
                                        {data_titres.parties.map((el, i) => {
                                            return (
                                                <li className="N_tel_item" key={i} onClick={() => setModaleMenuTel(false)}>
                                                    <NavLink
                                                        to={el.url}
                                                        className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "N_tel_active" : ""
                                                        }
                                                    >{el["name_" + language] !== undefined ? el["name_" + language].toUpperCase() : el.name_FR.toUpperCase()}
                                                    </NavLink>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>

                            </div>
                        }
                    </div>

                    <div className="N_tel_bottom_bar">
                        <a className="N_tel_top_left"
                            href="https://www.instagram.com/suzonleger/"
                            target="blank"
                            aria-label="compte instagram de l'artiste"
                        >
                            <svg
                                id="logo_insta"
                                width="35px"
                                height="35px"
                                viewBox="0 0 800 800"
                                xmlSpace="preserve"
                            >
                                <g>
                                    <path d="M395.8,97c-83.1,0-93.5,0.4-126.1,1.8c-32.6,1.5-54.8,6.6-74.2,14.2c-20.1,7.8-37.2,18.3-54.2,35.3
		c-17,17-27.5,34.1-35.3,54.2c-7.6,19.5-12.7,41.7-14.2,74.2c-1.5,32.6-1.8,43.1-1.8,126.1s0.4,93.5,1.8,126.1
		c1.5,32.6,6.7,54.8,14.2,74.2c7.8,20.1,18.3,37.2,35.3,54.2c17,17,34.1,27.5,54.2,35.3c19.5,7.6,41.7,12.7,74.2,14.2
		c32.6,1.5,43,1.8,126.1,1.8c83.1,0,93.5-0.4,126.1-1.8c32.6-1.5,54.8-6.6,74.3-14.2c20.1-7.8,37.1-18.3,54.1-35.3
		c17-17,27.5-34.1,35.3-54.2c7.5-19.5,12.7-41.7,14.2-74.2c1.5-32.6,1.8-43,1.8-126.1s-0.4-93.5-1.8-126.1
		c-1.5-32.6-6.7-54.8-14.2-74.2c-7.8-20.1-18.3-37.2-35.3-54.2c-17-17-34-27.5-54.2-35.3c-19.5-7.6-41.7-12.7-74.3-14.2
		C489.2,97.4,478.8,97,395.8,97L395.8,97z M368.4,152.1c8.1,0,17.2,0,27.4,0c81.7,0,91.3,0.3,123.6,1.8c29.8,1.4,46,6.3,56.8,10.5
		c14.3,5.5,24.4,12.2,35.1,22.9c10.7,10.7,17.3,20.9,22.9,35.2c4.2,10.8,9.2,27,10.5,56.8c1.5,32.2,1.8,41.9,1.8,123.6
		s-0.3,91.3-1.8,123.6c-1.4,29.8-6.3,46-10.5,56.8c-5.5,14.3-12.2,24.4-22.9,35.1c-10.7,10.7-20.9,17.3-35.1,22.9
		c-10.8,4.2-27,9.2-56.8,10.5c-32.2,1.5-41.9,1.8-123.6,1.8c-81.7,0-91.4-0.3-123.6-1.8c-29.8-1.4-46-6.4-56.8-10.5
		c-14.3-5.5-24.5-12.2-35.2-22.9c-10.7-10.7-17.3-20.9-22.9-35.2c-4.2-10.8-9.2-27-10.5-56.8c-1.5-32.2-1.8-41.9-1.8-123.6
		s0.3-91.3,1.8-123.6c1.4-29.8,6.3-46,10.5-56.8c5.5-14.3,12.2-24.5,22.9-35.2c10.7-10.7,20.9-17.3,35.2-22.9
		c10.8-4.2,27-9.2,56.8-10.5C300.5,152.5,311.4,152.1,368.4,152.1L368.4,152.1z M559.1,202.9c-20.3,0-36.7,16.4-36.7,36.7
		c0,20.3,16.4,36.7,36.7,36.7c20.3,0,36.7-16.4,36.7-36.7S579.4,202.9,559.1,202.9L559.1,202.9z M395.8,245.8
		c-86.7,0-157.1,70.3-157.1,157.1s70.3,157,157.1,157s157-70.3,157-157S482.6,245.8,395.8,245.8L395.8,245.8z M395.8,300.9
		c56.3,0,102,45.6,102,102c0,56.3-45.6,102-102,102c-56.3,0-102-45.6-102-102C293.9,346.5,339.5,300.9,395.8,300.9z"/>
                                </g>
                            </svg>
                        </a>
                        <div className="N_tel_top_right">
                            <button
                                onClick={() => setLanguage("FR")}
                                className="N_tel_btn_langue"
                            ><p>FR</p></button>
                            {
                                data_titres.langues.length > 1 &&
                                data_titres.langues.map((el, i) => {
                                    if (i > 0)
                                        return (
                                            <Fragment key={i}>
                                                <p className="N_tel_btn_separator">|</p>
                                                <button
                                                    onClick={() => setLanguage(el)}
                                                    className="N_tel_btn_langue"
                                                ><p>{el}</p></button>
                                            </Fragment>
                                        )
                                })}
                        </div>
                    </div>

                </div>
            }
            <Outlet />
        </>
    );
}