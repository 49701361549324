import React from 'react'
import './Carte_projet.css'
import { Link } from 'react-router-dom';

export default function Carte_projet({ projet, language }) {

    function applyLanguage(prop) {
        let ans = projet[prop + "_" + language] !== undefined ? projet[prop + "_" + language] : projet[prop + "_FR"]
        if (ans === undefined) {
            ans = projet[prop];
        }
        if (ans === undefined) {
            ans = "<!>" + prop + " manquante <!>";
            console.error("PROJET " + ": " + prop + " MANQUANTE !!");
        }
        return ans;
    }

    return (
        <Link to={"portfolio/" + projet.id}>
            <div className='CP_block'>
                <img
                    className='CP_img'
                    src={projet.img_couverture ? require('../data/img/' + projet.img_couverture) : ""}
                    alt={projet.alt_couverture ? projet.alt_couverture : "illustration du projet"}
                />
                {/* {window.innerWidth > 500 &&  */}
                <div className='CP_ombre'>
                    <h2 className='CP_text'>{applyLanguage("titre")}</h2>
                </div>
                {/* } */}
            </div>
        </Link>
    )
}
