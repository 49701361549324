import React from 'react'
import { useParams, Link } from 'react-router-dom'
import projets from '../data/projets_data';
import './Projet.css';

export default function Projet({ language }) {

    // console.log(projets)

    let { projetId } = useParams();

    let projet = projets.find(el => el.id === parseInt(projetId))

    function next() {
        let currentIndex = projets.findIndex(el => el.id === parseInt(projetId))
        if (currentIndex < projets.length - 1) {
            return projets[currentIndex + 1].id;
        } else {
            return projets[0].id
        }
    }

    function previous() {
        let currentIndex = projets.findIndex(el => el.id === parseInt(projetId))
        if (currentIndex === 0) {
            return projets[projets.length - 1].id;
        } else {
            return projets[currentIndex - 1].id
        }
    }

    function applyLanguage(prop) {
        let ans = projet[prop + "_" + language] !== undefined ? projet[prop + "_" + language] : projet[prop + "_FR"]
        if (ans === undefined) {
            ans = projet[prop]
        }
        if (ans === undefined) {
            ans = "<!>" + prop + " manquante <!>"
            console.error("PROJET " + projetId + ": " + prop + " MANQUANTE !!")
        }
        return ans;
    }

    return (
        <>
            {window.innerWidth > 500 &&
                <div className='P_wrap'>
                    <h2 className='P_titre_projet'>{applyLanguage("titre")}</h2>
                    <p className='P_spec'>{applyLanguage("technique")}</p>
                    <p className='P_spec'>{applyLanguage("taille")}</p>
                    <p className='P_spec'>{applyLanguage("année")}</p>
                    <p className='P_description'>{applyLanguage("description")}</p>
                    <img
                        src={projet.img_grille !== undefined ? require('../data/img/' + projet.img_grille) : projet.img_couverture !== undefined ? require('../data/img/' + projet.img_couverture) : ""}
                        alt={projet.alt_grille ? projet.alt_grille : "grille constituée de plusieurs images du projet"}
                        className='P_image'
                    />

                    <div className='P_arrow_block'>
                        {/* <Link to={(e) => previous()} */}
                        <Link
                            to={"/portfolio/" + previous()}
                            className='P_arrow_wrap'
                            aria-label={language === "FR" ? "projet précédent" : "previous project"}
                        >
                            <div className='P_arrow'>
                                <div className='P_arrow_left'>
                                    <div className='P_arrow_bar1'></div>
                                    <div className='P_arrow_bar2'></div>
                                </div>
                            </div>
                        </Link>
                        <Link
                            to={"/portfolio/" + next()}
                            className='P_arrow_wrap'
                            aria-label={language === "FR" ? "projet suivant" : "next project"}
                        >
                            <div className='P_arrow '>
                                <div className='P_arrow_right'>
                                    <div className='P_arrow_bar3'></div>
                                    <div className='P_arrow_bar4'></div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            }

            {window.innerWidth <= 500 &&
                <div className='P_tel_wrap'>
                    <h2 className='P_tel_titre_projet'>{applyLanguage("titre")}</h2>
                    <p className='P_tel_spec'>{applyLanguage("technique") + " - " + applyLanguage("taille") + " - " + applyLanguage("année")}</p>
                    <p className='P_tel_description'>{applyLanguage("description")}</p>
                    <img
                        className='P_tel_image'
                        src={projet.img_grille_tel !== undefined ? require('../data/img/' + projet.img_grille_tel) : projet.img_couverture !== undefined ? require('../data/img/' + projet.img_couverture) : ""}
                        alt={projet.alt_grille_tel ? projet.alt_grille_tel : "grille constituée de plusieurs images du projet"}
                    />
                    <div className='P_tel_arrow_block'>
                        <Link
                            to={"/portfolio/" + previous()}
                            className='P_tel_arrow_wrap'>
                            {/* <div className='P_tel_arrow'> */}
                            {/* <div className='P_tel_arrow_left'> */}
                            <div className='P_tel_arrow_bar1'></div>
                            <div className='P_tel_arrow_bar2'></div>
                            {/* </div> */}
                            {/* </div> */}
                        </Link>
                        <Link
                            to={"/portfolio/" + next()}
                            className='P_tel_arrow_wrap'>
                            {/* <div className='P_tel_arrow '> */}
                            {/* <div className='P_tel_arrow_right'> */}
                            <div className='P_tel_arrow_bar3'></div>
                            <div className='P_tel_arrow_bar4'></div>
                            {/* </div> */}
                            {/* </div> */}
                        </Link>
                    </div>
                </div>
            }
        </>
    )
}
