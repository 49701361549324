import React from 'react';
import './AtelierStage.css';

export default function AtelierStage({ language }) {


    console.log("===============");
    console.log(language);

    const dates = [
        {
            date_FR: "Week-end du 22 et 23 février 2025",
            date_EN: "Week-end du 22 et 23 février 2025",
            titre_FR: "Fabriquer son encre végétale",
            titre_en: "Fabriquer son encre végétale",
            lieu_FR: "Jardins du muséum (Borderouge) - Toulouse",
            lieu_EN: "Jardins du muséum (Borderouge) - Toulouse",
            description_FR: <p className='AS_atelier_description'>A partir de plantes, venez vous initier à la fabrication de vos couleurs pour peindre.</p>,
            url: "https://museum.toulouse-metropole.fr/agenda/",
        },

        {
            date_FR: "Week-end du 10 et 11 mai 2025",
            date_EN: "Week-end du 10 et 11 mai 2025",
            titre_FR: "Esquisser le jardin",
            lieu_FR: "Jardins du muséum (Borderouge) - Toulouse",
            lieu_EN: "Jardins du muséum (Borderouge) - Toulouse",
            description_FR: <p className='AS_atelier_description'>A l’aide de loupes, nous irons dans le jardin à la recherche de petits détails de la faune et de la flore qui composent le jardin : le but sera de les observer et de les reproduire (idéalement dans un petit carnet d’observation) à l’aide de crayons de couleurs. Pour introduire cet exercice, je proposerai des «mini-jeux» pour apprendre à regarder et détendre le poignet pour se laisser aller sur la feuille.</p>,
            url: "https://museum.toulouse-metropole.fr/agenda/",
        },
    ]


    function applyLanguage(el, prop) {
        let ans = el[prop + "_" + language] !== undefined ? el[prop + "_" + language] : el[prop + "_FR"]
        if (ans === undefined) {
            ans = el[prop]
        }
        if (ans === undefined) {
            ans = "<!>" + prop + " manquante <!>"
            console.error("PROJET " + el.titre_FR + ": " + prop + " MANQUANTE !!")
        }
        return ans;
    }

    return (
        <div className='AS_main'>

            {language === "FR" && <h2 className='AS_atelier_h2'>Prochains ateliers et stages</h2>}
            {language === "EN" && <h2 className='AS_atelier_h2'>Next classes and workshops</h2>}

            {language === "FR" && <p>Pour le moment, je ne dispose pas d'un atelier où dispenser des cours ou des stages/workshops. Je propose des ateliers pour des structures artistiques, culturelles ou scientifiques ou encore des entreprises. N'hésitez pas à me contacter pour toute collaboration.</p>}
            {language === "EN" && <p>Pour le moment, je ne dispose pas d'un atelier où dispenser des cours ou des stages/workshops. Je propose des ateliers pour des structures artistiques, culturelles ou scientifiques ou encore des entreprises. N'hésitez pas à me contacter pour toute collaboration.</p>}

            {language === "FR" && <h3>Ateliers programmés en 2025</h3>}
            {language === "EN" && <h3>Ateliers programmés en 2025</h3>}

            {dates.sort((a, b) => b.date - a.date).map((el, i) => {

                return (
                    <div key={i} className='AS_atelier_block'>

                        <p className='AS_atelier_date'>{applyLanguage(el, "date")}</p>

                        <p className='AS_atelier_titre'>{applyLanguage(el, "titre")}</p>

                        <p className='AS_atelier_lieu'>{applyLanguage(el, "lieu")}</p>

                        {/* <p className='AS_atelier_description'>{applyLanguage(el, "description")}</p> */}
                        {applyLanguage(el, "description")}

                        <a
                            className='AS_atelier_link'
                            href={el.url}
                            target="blank"
                            aria-label="compte instagram de l'artiste"
                        >S'inscrire
                        </a>
                    </div>
                )
            })}

            <div className='AS_atelier_img'>
                <img
                    src={require('../data/img/contact2.png')}
                    alt="artiste au travail"
                    className='AS_atelier_img'
                />
            </div>
        </div>
    )
}


